import React from 'react';

import NumberWComma from 'utils/number-w-commas';

import './styles.less';
import { Col, Row } from 'antd';
import translate from 'i18n/translate';

type Installment = {
  approved: Boolean,
  id: Number,
  price: String,
  reference_number: String,
}

type BankDetail = {
  account_name: String,
  account_number: String,
  bank: String,
  branch: String,
  installment_plan_id: Number,
  reference_number: String,
  installments: Installment,
  amount: String
}

type BankDetailsProps = {
  bankDetails: Array<BankDetail>;
}

const BankDetails = ({ bankDetails }: BankDetailsProps) => {
  const installment = bankDetails[0].installments

  return (
    <div className="bank-details">
      <div>

      <Row>
        <Col>
          <ul>
            <li>Lurn.lk {translate('bankPayment.completePurchase.bankDetails.topTitle')}</li>
          </ul>
        </Col>
      </Row>

      <br/>
      <Row>
        {
          bankDetails.map((bank_detail, index)=>{
            return(
              <Col xs={24} sm={24} md={12} lg={12} key={index}>
                  <ul>
                    <li>{translate('bankPayment.completePurchase.bankDetails.bank')}: <span>{bank_detail.bank}</span></li>
                    <li>{translate('bankPayment.completePurchase.bankDetails.branch')}: <span>{bank_detail.branch}</span></li>
                    <li>{translate('bankPayment.completePurchase.bankDetails.accountName')}: <span>{bank_detail.account_name}</span></li>
                    <li>{translate('bankPayment.completePurchase.bankDetails.accountNumber')}: <span>{bank_detail.account_number}</span></li>
                  </ul>
              </Col>
            )
          })
        }
      </Row>

      <br/>
      <Row>
        <Col>
          <ul>
            <li>{translate('bankPayment.completePurchase.bankDetails.bottomTitle')}</li>
          </ul>
        </Col>
      </Row>

      <Row>
        <Col>
          <ul>
            <li>
              {translate('bankPayment.completePurchase.bankDetails.referenceNumber')}: 
              <span> {installment ? installment.reference_number : bankDetails[0].reference_number}</span>
              {translate('bankPayment.completePurchase.bankDetails.referenceNumberWarning')}
            </li>
            <li>{translate('bankPayment.completePurchase.bankDetails.amount')}: <span>Rs. {NumberWComma(installment && installment.price ? Number(installment.price) : Number(bankDetails[0].amount))}</span></li>
          </ul>
        </Col>
      </Row>
      </div>
    </div>
  );
};

export default BankDetails;
